<template>
	<v-card flat class="notificationscard my-1">
		<!-- Card Header -->
		<div class="cardheader d-flex align-center justify-space-between pa-1" style="min-width: 30%">
			<div class="d-flex nowrap align-center">
				<notifications-card-icon class="mx-2" :feature="feature"></notifications-card-icon>
				<div class="cardheadertitle mx-2">{{ notificationId }}</div>
			</div>
			<div class="d-flex nowrap align-center">
				<v-tooltip v-if="feature['is_average'] == 1" top transition="none" :color="primaryColor">
					<template v-slot:activator="{ on, attrs }">
						<v-chip :color="primaryColor" class="px-2 mr-1" link pill small v-bind="attrs" v-on="on">
							<span class="fs-xs fw-bold color-white">AVG</span></v-chip
						>
					</template>
					<span class="fs-regular">{{ $t('panels.notifications.average_operator') }}</span>
				</v-tooltip>
				<v-btn text icon class="cardheaderbtn" @click="openPanel" :title="buttonTitle">
					<i class="fas fa-arrow-right"></i>
				</v-btn>
			</div>
		</div>
		<v-divider style="height: 1px; background: rgba(0, 0, 0, 0.05)"></v-divider>
		<!-- Date info -->
		<v-list lines="one" density="compact" class="date-container d-flex justify-start py-0 px-3">
			<v-icon size="14" class="fw-500 pr-2">fak fa-solid-hour</v-icon
			><span class="date-text fw-regular fs-sm ma-0 px-0 py-1">
				{{ getElasticSearchDate() }}
			</span>
		</v-list>
		<v-divider style="height: 1px; background: rgba(0, 0, 0, 0.05)"></v-divider>
		<!-- Card body -->
		<div class="cardbody d-flex flex-column justify-center pa-1">
			<card-parameter :feature="feature" type="notification"></card-parameter>
		</div>
	</v-card>
</template>

<script>
import NotificationsCardIcon from './NotificationsCardIcon';
import CardParameter from '@/components/map/sections/info/card/CardParameter';

import { getPanelOptions } from '@/lib/utils/features';
import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';
import { formatTimestamp } from '@/api/common';

export default {
	name: 'NotificationsCard',

	data() {
		return {
			showVisitorsDialog: false,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},

	props: {
		feature: {
			type: Object,
			required: true
		},

		nosupplier: {
			type: Boolean,
			default: false,
			required: false
		}
	},

	components: {
		NotificationsCardIcon,
		CardParameter
	},

	computed: {
		notificationId() {
			const { station_code } = this.feature;

			/* console.log('THIS FEATURE');
			console.log(this.feature); */
			return station_code || '-';
		},

		buttonTitle() {
			return this.$t('panels.notifications.goDetailNotification');
		}
	},

	methods: {
		openPanel() {
			console.log('== OPEN PANEL FEATURE ==', this.feature);
			const options = getPanelOptions(NOTIFICATIONS_LAYER, this.feature);
			console.log('panel option debug', options);
			this.$store.commit('addDynamicPanel', options);
			this.$store.commit('setActivePanel', options);
			this.centerOnDevice(this.feature);
		},
		getElasticSearchDate() {
			console.log('feature notification', this.feature);
			const timestamp = parseInt(this.feature['start_date']);
			console.log(timestamp);

			return formatTimestamp(timestamp, this.userProperties);
		},
		centerOnDevice(device) {
			if (device) this.$store.commit('setDeviceZoom', device);
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';
.notificationscard {
	border: 1px solid var(--light-blue-grey) !important;
	border-radius: 4px;
	background-color: var(--negative) !important;
	& .cardheader {
		//border-bottom: 1px solid var(--light-blue-grey);
		& .cardheadertitle {
			font-size: 1.1rem;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: var(--high);
			text-transform: capitalize !important;
			word-break: break-word;
		}
		& .cardheaderbtn {
			color: var(--high);
		}
	}
	& .cardbody {
		//border-bottom: 1px solid var(--light-blue-grey);
		min-height: 29px;
	}
}
</style>
